import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import 'dayjs/locale/es';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import BlockScreen from '../blockScreen/blockScreen';
// import * as Yup from 'yup';
import Loader from '../../elements/loader';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { ParticularExpense, ExpenseReportRequestToShow, OrganizationalUnit,
  BenefitCenter, BillAccount } from '../../types/fields';
import performApiRequest from '../../utils/performApiRequest';
import { useDataForEndpoint } from '../../context/dataProvider';
import ExpenseReportRequestFilesModal from '../../elements/expenseRequestFilesModal';
import UploadFileToExpenseReportRequest from './uploadFileToExpenseReportRequest';
import EditIcon from '@mui/icons-material/Edit';
import showExactDate from '../../utils/showExactDate';
import { API_ENDPOINTS } from '../../utils/endpoints';
import { FIELD_OPTIONS_ENDPOINTS } from '../../utils/fieldOptionsEndpoints';
import { useToast } from '../../context/useToast';
import ConvertDateToFullTime from '../../utils/convertDateToFullTime';
import { List, ListItem, ListItemText } from '@mui/material';
import { useAccessPermissions } from '../../hooks/useAccessPermission';
import { DownloadParticularExcelButton } from '../../elements/downloadParticularExcelButton';
import ConvertDateToDayMonthYear from '../../utils/convertDateToDayMonthYear';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Santiago');

// const particularExpenseSchema = Yup.object().shape({
//     brief_description: Yup.string().required('Ingresa una descripción. Ejemplo: Artículos de oficina.'),
//     document_type: Yup.string().oneOf(['Factura', 'Boleta', 'Comprobante de egreso'], 'Debe ser una de las opciones disponibles.').required('Debes seleccionar una de las opciones.'),
//     document_number: Yup.string().required('Debes ingresar el no. de documento.'),
//     business_name: Yup.string().required('Ingresa el nombre de la empresa.'),
//     business_rut: Yup.string().required('Ingresa el RUT del proveedor o equivalente.'),
//     bill_account_id: Yup.number(),
//     benefit_center_prc_code: Yup.string().required('Debes seleccionar el centro de beneficio.'),
//     project_number: Yup.string(),
//     amount: Yup.number().required('Ingresa el monto indicado en el documento.').min(1, 'El monto no puede ser de 0 o menor.'),
//     currency_id: Yup.number().required('Debes seleccionar una de las divisas.'),
//     files: Yup.array().of(Yup.mixed().required('Debes adjuntar al menos un archivo.')),
//     expense_date: Yup.string().required('Debes ingresar la fecha del gasto.'),
//     commentary: Yup.string()
// });

// const baseSchema = Yup.object().shape({
//     request_title: Yup.string().required('Asígnale un nombre a la solicitud'),
//     requesting_employee_name: Yup.string().required('Ingresa el nombre completo del funcionario solicitante.'),
//     requesting_employee_rut: Yup.string().required('Ingresa el RUT del funcionario solicitante.'),
//     expense_request_type: Yup.string().oneOf(['Rendición de fondos por rendir', 'Solicitud de reembolso', 'Rendición de fondos rotatorios'], 'Debe ser una de las opciones disponibles.').required('Debes seleccionar una opción.'),
//     requesting_unit_id: Yup.string().required('Debes seleccionar una unidad organizacional.'),
//     assigned_funds: Yup.number().min(0, 'Los fondos asignados no pueden ser negativos.'),
//     currency_id: Yup.number(),
//     in_favor_of_solicitant: Yup.boolean().required('Debes marcar una opción.'),
//     commentary: Yup.string(),
//     beneficiary_name: Yup.string(),
//     beneficiary_current_account_number: Yup.string(),
//     beneficiary_rut: Yup.string(),
//     beneficiary_bank: Yup.string(),
//     form_completer_signature_present: Yup.boolean(),
//     particular_expenses: Yup.array().of(particularExpenseSchema)
// });

export default function ShowExpenseReportRequest () {
    
    const isAuthenticated = useAuthStatus();
    const [billAccounts, setBillAccounts] = useState<BillAccount[]>([]);
    const benefitCenters = useDataForEndpoint<BenefitCenter[]>('benefit_centers');
    const organizationalUnits = useDataForEndpoint<OrganizationalUnit[]>('organizational_units');
    const [expenseReportRequest, setExpenseReportRequest] = useState<ExpenseReportRequestToShow>()
    const [permissions, setPermissions] = useState<any>([])
    const [nextApprovers, setNextApprovers] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { showErrorToast, showSuccessToast } = useToast();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFileUploadModalOpen, setFileUploadModalOpen] = useState(false);
    const [comments, setComments] = useState(Array(expenseReportRequest?.particular_expenses.length).fill(""));
    const [statuses, setStatuses] = useState(Array(expenseReportRequest?.particular_expenses.length).fill(null));
    const [generalComment, setGeneralComment] = useState('');
    const { accessPermissions } = useAccessPermissions();

    const handleAccept = (index: number) => {
        // Toggle between null and 'Aprobado'
        setStatuses(statuses.map((status, i) => i === index ? 'Aprobado' : status));
      };
    
      const handleReject = (index: number) => {
        // Toggle between null and 'Rechazado'
        setStatuses(statuses.map((status, i) => i === index ? 'Rechazado' : status));
      };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedExpense, setSelectedExpense] = useState<ParticularExpense | null>(null);
    const { id } = useParams();
    const [activeModalExpenseId, setActiveModalExpenseId] = useState<number | null>(null);

    useEffect(() => {
        fetchBillAccounts();
      }, []);

      const fetchBillAccounts = async () => {
        try {
          const requestOptions = { method: 'GET' };
          const response = await performApiRequest(FIELD_OPTIONS_ENDPOINTS.bill_accounts, requestOptions);
          if (response.status === 200) {
            setBillAccounts(response.data);
          }
        } catch (error) {
          console.error('Error fetching bill accounts:', error);
        }
    };

    const handleOpenModal = (expense: ParticularExpense) => {
        setSelectedExpense(expense);
        setActiveModalExpenseId(expense.id);
        setIsModalOpen(true);
    };

    const handleCommentChange = (index: number, newComment: string) => {
        const updatedComments = comments.map((comment, i) => i === index ? newComment : comment);
        setComments(updatedComments);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
      setActiveModalExpenseId(null);
    };

    const openFileUploadModal = () => {
        setFileUploadModalOpen(true);
    }

    const approveAllExpenses = () => {
        if (expenseReportRequest) {
            const newStatuses = expenseReportRequest.particular_expenses.map(expense =>
                expense.status === 'Rechazado' ? 'Rechazado' : 'Aprobado'
            );
            setStatuses(newStatuses);
        } else {
            showErrorToast('Ocurrió un error. Inténtalo más tarde.')
        }
    };
    
    const rejectAllExpenses = () => {
        if (expenseReportRequest) {
            const newStatuses = expenseReportRequest.particular_expenses.map(() => 'Rechazado');
            setStatuses(newStatuses);
        } else {
            showErrorToast('Ocurrió un error. Inténtalo más tarde.')
        }
    };

    useEffect(() => {
        const allData = [
          organizationalUnits,
          benefitCenters,
          billAccounts
        ];
        
        if (isAuthenticated) {
            const isAnyDataUndefined = allData.some(data => data === undefined);
            setIsLoading(isAnyDataUndefined);
        }
    
        // Set loading to false if all data is loaded
    }, [organizationalUnits, benefitCenters, billAccounts, isAuthenticated]);
    
    const closeFileUploadModal = () => {
        setFileUploadModalOpen(false);
    }

    const handleEdit = (requestData: any) => {
        navigate('/requestExpenseReport', { state: { requestData } });
    };

    const sendApprovalToBackend = async () => {
        if (expenseReportRequest) {
            try {
                const decisions = expenseReportRequest.particular_expenses.map((expense, index) => ({
                    expenseId: expense.id,
                    action: statuses[index],
                    comment: comments[index]
                }));

                const isValid = decisions.every(decision => {
                    return decision.action !== 'Rechazado' || (decision.action === 'Rechazado' && decision.comment.trim() !== '');
                });
    
                if (!isValid) {
                    showErrorToast('Todos los rechazos deben tener un comentario.');
                    return;
                }
    
                const requestOptions = {
                    method: 'PATCH',
                    data: {
                        decisions,
                        request_id: expenseReportRequest.id,
                        comment: generalComment,
                    },
                };

                if (['En proceso de revisión', 'Enviado'].includes(expenseReportRequest.status)) {
                    const response = await performApiRequest(API_ENDPOINTS.reviewExpenseReportRequest, requestOptions);
                    if (response.status === 204) {
                        showSuccessToast('Revisión enviada correctamente.');
                        navigate('/allExpenseReportRequests');
                    }
                }

                else if (expenseReportRequest.status === 'Pendiente de validación por Contabilidad') {
                    const response = await performApiRequest(API_ENDPOINTS.validateExpenseReportRequestAsAccounting, requestOptions);
                    if (response.status === 204) {
                        showSuccessToast('Revisión enviada correctamente.');
                        navigate('/allExpenseReportRequests');
                    }
                }

                else if (expenseReportRequest.status === 'Validado por Contabilidad') {
                    const response = await performApiRequest(API_ENDPOINTS.markExpenseReportRequestAsApproved, requestOptions);
                    if (response.status === 204) {
                        showSuccessToast('Revisión enviada correctamente.');
                        navigate('/allExpenseReportRequests');
                    }
                }

            } catch (err: any) {
                showErrorToast('Error: ', err)
            }
        }
    };

    const sendBackToSolicitant = async () => {
        if (expenseReportRequest) {
            try {
                const decisions = expenseReportRequest.particular_expenses.map((expense, index) => ({
                    expenseId: expense.id,
                    action: statuses[index],
                    comment: comments[index]
                }));

                const isValid = decisions.every(decision => {
                    return decision.action !== 'Rechazado' || (decision.action === 'Rechazado' && decision.comment.trim() !== '');
                });
    
                if (!isValid) {
                    showErrorToast('Todos los rechazos deben tener un comentario.');
                    setIsLoading(false);
                    return;
                }
    
                const requestOptions = {
                    method: 'PATCH',
                    data: {
                        decisions,
                        request_id: expenseReportRequest.id,
                        comment: generalComment,
                    },
                };
                const response = await performApiRequest(API_ENDPOINTS.sendExpenseReportRequestBackToSolicitant, requestOptions);
                if (response.status === 204) {
                    showSuccessToast('Solicitud enviada de vuelta al solicitante.');
                    navigate('/allExpenseReportRequests');
                }
            } catch (err: any) {
                showErrorToast('Error: ', err)
            }
        }
    };


    const sendRejectToBackend = async () => {
        if (expenseReportRequest) {
            try {
                const decisions = expenseReportRequest.particular_expenses.map((expense, index) => ({
                    expenseId: expense.id,
                    action: statuses[index],
                    comment: comments[index]
                }));

                const isValid = decisions.every(decision => {
                    return decision.action !== 'Rechazado' || (decision.action === 'Rechazado' && decision.comment.trim() !== '');
                });
    
                if (!isValid) {
                    showErrorToast('Todos los rechazos deben tener un comentario.');
                    setIsLoading(false);
                    return;
                }
    
                const requestOptions = {
                    method: 'PATCH',
                    data: {
                        decisions,
                        request_id: expenseReportRequest.id
                    },
                };
                const response = await performApiRequest(API_ENDPOINTS.rejectExpenseReportRequest, requestOptions);
                if (response.status === 204) {
                    showSuccessToast('Solicitud rechazada correctamente.');
                    navigate('/allExpenseReportRequests');
                }
            } catch (err: any) {
                showErrorToast('Error: ', err)
            }
        }
    };


    useEffect(() => {
        if (isAuthenticated) {
            const fetchRequest = async () => {
                try {
                    const endpoint = `/v1/expenseReportRequestController/particularExpenseReportRequest/${id}`;
                    const requestOptions = {
                        method: 'GET'
                    }
                    const response = await performApiRequest(endpoint, requestOptions);
                    setExpenseReportRequest(response.data.request);
                    setPermissions(response.data.permissions);
                    setNextApprovers(response.data.nextApprovers);
                    console.log(response.data.request);
                    console.log(response.data.permissions);
                } catch (error) {
                    console.error('There was an error fetching the request:', error);
                }
            };
    
            if (isAuthenticated) {
                fetchRequest();
                setIsLoading(false);
            }
        }
    }, [isAuthenticated, id]);


    useEffect(() => {
        if (expenseReportRequest) {
            const newComments = expenseReportRequest.particular_expenses.map(expense =>
              expense.status === 'Rechazado' ? 'Gasto Rechazado Previamente' : ''
            );
            setComments(newComments);
        }
        //setComments(Array(expenseReportRequest?.particular_expenses.length).fill(""));
        setStatuses(Array(expenseReportRequest?.particular_expenses.length).fill(null));
    }, [expenseReportRequest]);

    if (!isAuthenticated) {
        return <BlockScreen/>
    }

    if (isLoading) {
        return <Loader message={"Cargando información..."}/>
    }

    if (!expenseReportRequest || !nextApprovers || !permissions) {
        return <Loader/>
    }

    return (
        <div className="flex flex-col md:flex-row p-4 bg-white shadow rounded-lg items-center justify-center">
        {/*<ToastContainer/>*/}
        <div className="mb-6">
            <h1 className="text-gray-700 text-xl font-bold mb-5">Solicitud no. {expenseReportRequest.id} - Realizada el {ConvertDateToDayMonthYear(expenseReportRequest.createdAt)}</h1>
            
            <button
                onClick={() => handleEdit(expenseReportRequest)}
                className="text-left items-center justify-left flex flex-row bg-blue-600 hover:bg-blue-800 text-white py-1 px-2 rounded-md mb-5">
                <h4 className="mr-4"> Editar solicitud </h4> 
                <EditIcon/>
            </button>

            <DownloadParticularExcelButton
                id={`${expenseReportRequest.id}`}
                entity="expenseReportRequest"
            />

            <DownloadParticularExcelButton
                id={`${expenseReportRequest.id}`}
                entity="particularExpenseDataTransfer"
            />

            <h1 className="text-gray-700 text-lg font-bold mb-5">Datos del solicitante</h1>
                {/*<ToastContainer/>*/}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">

                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Funcionario que envió el formulario
                        </label>
                        <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {expenseReportRequest.formCompleterUserData.names} {expenseReportRequest.formCompleterUserData.ap_pat} {expenseReportRequest.formCompleterUserData.ap_mat}
                        </p>
                    </div>

                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Funcionario que desea hacer la rendición de gastos
                        </label>
                        <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {expenseReportRequest.requestingUserData.names} {expenseReportRequest.requestingUserData.ap_pat} {expenseReportRequest.requestingUserData.ap_mat}
                        </p>
                    </div>

                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            RUT del funcionario
                        </label>
                        <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {expenseReportRequest.requestingUserData.rut}-{expenseReportRequest.requestingUserData.dv_rut}
                        </p>
                    </div>

                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Tipo de solicitud
                        </label>
                        <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {expenseReportRequest.expense_request_type}
                        </p>
                    </div>

                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Unidad solicitante
                        </label>
                        <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {organizationalUnits.find(unit => unit.organizational_unit_id === expenseReportRequest.requesting_unit_id)?.organizational_unit_name ?? 'Información no disponible.'}
                        </p>
                    </div>

                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Estado
                        </label>
                        <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {expenseReportRequest.status}
                        </p>
                    </div>

                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Monto total de la solicitud
                        </label>
                        <p className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {Math.abs(expenseReportRequest.total_balance)} {expenseReportRequest.in_favor_of_solicitant ? " CLP a favor del solicitante" : " CLP, no a favor del solicitante"}
                        </p>
                    </div>
                </div>
            <h1 className="font-bold mb-6 text-lg text-gray-800"> Detalle de la rendición </h1>


            <div className="grid grid-cols-2 gap-4 mt-4">
                <button
                    onClick={approveAllExpenses}
                    className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-green-400 hover:bg-green-600 text-white">
                        Aprobar todos los gastos
                </button>
                <button
                    onClick={rejectAllExpenses}
                    className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-red-400 hover:bg-red-600 text-white">
                        Rechazar todos los gastos
                </button>
            </div>

            {expenseReportRequest.particular_expenses.map((expense, index) => (
                <div className="mb-5 bg-gray-50 rounded-lg px-5 py-5" key={index}>
                    <div className="flex flex-col md:flex-row justify-between items-center w-full">
                        <div className="flex items-center justify-left mb-5">
                            <h1 className="font-bold mr-5"> Gasto {index + 1} </h1>
                        </div>
                        {expense.status !== 'Rechazado' ? (
                            <div key={index} className="flex flex-end flex-row space-x-2">
                                <button
                                    onClick={() => handleAccept(index)}
                                    className={`px-3 py-1 rounded text-white ${statuses[index] === 'Aprobado' ? 'bg-green-500' : 'bg-gray-300 hover:bg-green-500'}`}
                                >
                                    Aprobar
                                </button>
                                <button
                                    onClick={() => handleReject(index)}
                                    className={`px-3 py-1 rounded text-white ${statuses[index] === 'Rechazado' ? 'bg-red-500' : 'bg-gray-300 hover:bg-red-500'}`}
                                >
                                    Rechazar
                                </button>
                            </div>
                            ) : (
                                <span className="text-red-600 font-bold"> Gasto rechazado </span>
                            )
                        }

                    </div>
                    <div className="mb-5 bg-gray-50 rounded-lg px-5 py-5">
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center">
                    
                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                Tipo de documento
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expense.document_type}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                Número de documento
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expense.document_number}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                Razón social del proveedor
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expense.business_name}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="business_rut">
                                RUT del proveedor
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expense.business_rut}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                Centro de beneficio
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expense.benefit_center_prc_code} - {benefitCenters.find(center => center.prc_code === expense.benefit_center_prc_code)?.benefit_center_name ?? 'Información no disponible.'}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                Categoría del gasto
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {(expense.category && expense.category.description) ? expense.category.description : 'Información no disponible.'}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                Número de proyecto
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expense.project_number !== '' ? expense.project_number : 'Información no disponible.'}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                Monto ({expense.currency ? expense.currency.currency_code : 'Divisa no disponible' })
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expense.amount ? expense.amount : 'Información no disponible.'}
                            </p>
                        </div>

                        { expense.currency && expense.currency.currency_code && expense.currency.currency_code !== 'CLP' && (
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                    Tasa de cambio del día del gasto
                                </label>
                                <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                    {expense.exchange_rate ? expense.exchange_rate : 'Tasa no disponible' }
                                </p>
                            </div>
                        )}

                        { expense.currency && expense.currency.currency_code && expense.currency.currency_code !== 'CLP' && (
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                    Monto (CLP)
                                </label>
                                <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                    {expense.clp_amount ? expense.clp_amount : 'Información no disponible.'}
                                </p>
                            </div>
                        )}


                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="expense_date">
                                Fecha del gasto
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expense.expense_date ? showExactDate(expense.expense_date) : 'Información no disponible.'}
                                {/*expense.expense_date ?  dayjs(expense.expense_date).format('YYYY-MM-DD') : 'Información no disponible.'*/}
                            </p>
                        </div>

                        {(expense.note !== '') && (
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                    Nota
                                </label>
                                <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                    {expense.note}
                                </p>
                            </div>
                        )}

                    </div>
                    <div>
                        <button onClick={() => handleOpenModal(expense)} className="mt-4 w-full text-white bg-customBlue hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex-end">
                            Ver documentos
                        </button>
                    </div>
                    {activeModalExpenseId === expense.id && (
                        <ExpenseReportRequestFilesModal
                            isOpen={activeModalExpenseId === expense.id}
                            uploadedFiles={expense.files}
                            onClose={handleCloseModal}
                            openFileUploadModalInParent={openFileUploadModal}
                        />
                    )}
                    { isFileUploadModalOpen && (
                        <UploadFileToExpenseReportRequest actual_request_id={expenseReportRequest.id} onClose={closeFileUploadModal}/>
                    )}
                    </div>
                    {expense.status !== 'Rechazado' ? (
                    <input
                        type="text"
                        value={comments[index] || ""}
                        onChange={(e) => handleCommentChange(index, e.target.value)}
                        placeholder="Agrega un comentario"
                        disabled={expense.status === 'Rechazado'}
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                    />
                    ) : (
                        
                        <input
                        type="text"
                        value={comments[index] || ""}
                        onChange={(e) => handleCommentChange(index, e.target.value)}
                        placeholder="Agrega un comentario"
                        disabled={expense.status === 'Rechazado'}
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                    />
                    )}
                    {expense.particularExpenseApprovalHistory.length > 0 && (
                        <div className="px-1 py-1 border rounded-md border-gray-400 shadow-sm bg-white">
                            <h4 className="font-bold text-md mb-2">Historial del gasto</h4>
                            {expense.particularExpenseApprovalHistory.map((event: any) => (
                                <div key={event.id} className="mb-1 text-sm">
                                    <span>{ConvertDateToFullTime(event.createdAt)}</span>
                                    {event.status === 'Aprobado' && (
                                        <span> - {event.userData.names} {event.userData.ap_pat} {event.userData.ap_mat} aprobó el gasto {event.comment ? ` con el siguiente comentario: ${event.comment}` : ''}</span>
                                    )}
                                    {event.status === 'Rechazado' && (
                                        <span> - {event.userData.names} {event.userData.ap_pat} {event.userData.ap_mat} rechazó el gasto {event.comment ? ` con el siguiente comentario: ${event.comment}` : ''}</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
            <div className="items-center justify-center my-5">
                <h1 className="font-bold mb-5 text-lg text-gray-800"> Cálculo del saldo </h1>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mb-5 justify-center">

                    <div>
                        {expenseReportRequest.expense_request_type !== 'Solicitud de reembolso' ? (
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                    Monto del fondo asignado (CLP)
                                </label>
                                <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                    {expenseReportRequest.assigned_funds ? expenseReportRequest.assigned_funds : 0}
                                </p>
                            </div>
                        ) : (null)}
                    </div>
                    <div> </div>
                
                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Total rendición (CLP)
                        </label>
                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {expenseReportRequest.total_rendition ? expenseReportRequest.total_rendition : 'Información no disponible.'}
                        </p>
                    </div>
                    <div> </div>

                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Total saldo solicitado (CLP)
                        </label>
                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {expenseReportRequest.total_balance ? expenseReportRequest.total_balance : 'Información no disponible.'}
                        </p>
                    </div>
                    <div> </div>

                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            Total saldo aprobado (CLP)
                        </label>
                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {expenseReportRequest.total_approved ? expenseReportRequest.total_approved :  expenseReportRequest.total_balance ? expenseReportRequest.total_balance : 'Información no disponible.'}
                        </p>
                    </div>
                    <div> </div>
                    
                    <div>
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                            ¿A favor del solicitante?
                        </label>
                        <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {expenseReportRequest.in_favor_of_solicitant ? 'Saldo a favor del solicitante.' : 'No.'}
                        </p>
                    </div>
                    <div> </div>
                
                </div>
                <div className="mb-4">
                    <label htmlFor="total" className="block text-gray-700 text-lg font-bold mb-2 mt-6 text-center">
                        Historial de la solicitud
                    </label>
                    <div className='overflow-x-auto'>
                    <div className="min-w-screen">
                    <table className="min-w-full divide-y divide-gray-200 shadow-sm">
                        <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-center text-md font-medium text-gray-500">
                            Acción realizada
                            </th>
                            <th className="px-6 py-3 text-center text-md font-medium text-gray-500">
                            Agente
                            </th>
                            <th className="px-6 py-3 text-center text-md font-medium text-gray-500">
                            Comentario
                            </th>
                            <th className="px-6 py-3 text-center text-md font-medium text-gray-500">
                            Fecha
                            </th>
                        </tr>
                        </thead>
                            { (expenseReportRequest.expenseReportApprovalHistory.length > 0) ? (
                                <tbody className="bg-white divide-y divide-gray-200">
                                {expenseReportRequest.expenseReportApprovalHistory.map((action: any, index: number) => (
                                    <tr key={action.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{ActionDictionary[action.action_taken]}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{action.userData.names} {action.userData.ap_pat} {action.userData.ap_mat}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{action.comment}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{ConvertDateToFullTime(action.createdAt)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            ) : (
                                <caption className="font-custom text-sm text-grey-800 text-center">
                                    La solicitud aún no tiene historial de aprobaciones.
                                </caption>
                            )}

                    </table>
                    </div>
                    </div>
                </div>


                {(expenseReportRequest.in_favor_of_solicitant) ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mb-5">
                        
                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                Nombre del beneficiario
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expenseReportRequest.beneficiary_name ? expenseReportRequest.beneficiary_name : 'Información no disponible.'}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                RUT del beneficiario
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expenseReportRequest.beneficiary_rut ? expenseReportRequest.beneficiary_rut : 'Información no disponible.'}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                Banco del beneficiario
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expenseReportRequest.beneficiary_bank ? expenseReportRequest.beneficiary_bank : 'Información no disponible.'}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="requesting_employee_name">
                                No. de cuenta corriente del beneficiario
                            </label>
                            <p className="text-sm text-center shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                {expenseReportRequest.beneficiary_current_account_number ? expenseReportRequest.beneficiary_current_account_number : 'Información no disponible.'}
                            </p>
                        </div>

                    </div>
                ) : (null)}
                </div>
                <div className="grid grid-cols-1 gap-4">
                    <input
                        type="text"
                        value={generalComment || ""}
                        onChange={(e) => setGeneralComment(e.target.value)}
                        placeholder="Agrega un comentario"
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                    />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        { !['Aprobado', 'Cerrado'].includes(expenseReportRequest.status) && (
                            <button
                                disabled={!expenseReportRequest.particular_expenses.every(expense => expense.status === 'Rechazado') &&
                                        (!statuses || !statuses.every((status: any) => status === 'Rechazado'))}
                                onClick={() => sendRejectToBackend()}
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-red-400 hover:bg-red-600 text-white">
                                Rechazar informe
                            </button>
                        )}
                    </div>
                    <div>
                        {(expenseReportRequest.particular_expenses.every(expense => expense.status === 'Rechazado') || 
                        (statuses && statuses.every((decision: any) => decision === 'Rechazado'))) && (
                            <button
                                disabled={!expenseReportRequest.particular_expenses.every(expense => expense.status === 'Rechazado') &&
                                        (!statuses || !statuses.every((status: any) => status === 'Rechazado'))}
                                onClick={() => sendBackToSolicitant()}
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-yellow-400 hover:bg-yellow-600 text-white">
                                Devolver informe al solicitante
                            </button>
                        )}
                    </div>
                    <div>
                        { (['Enviado', 'En proceso de revisión'].includes(expenseReportRequest.status) && (permissions.chainAgentTurn.hasReviewPermissions || accessPermissions.expenseReportSystem.adminPermissions)) && (
                            <button
                                onClick={() => sendApprovalToBackend()}
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white">
                                Enviar al siguiente aprobador
                            </button>
                        )}
                        { (['Pendiente de validación por Contabilidad'].includes(expenseReportRequest.status) && accessPermissions.expenseReportSystem.accountingPermissions) && (
                            <button
                                onClick={() => sendApprovalToBackend()}
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white">
                                Validar como Contabilidad
                            </button>
                        )}
                        { (['Validado por Contabilidad'].includes(expenseReportRequest.status) && accessPermissions.expenseReportSystem.treasuryPermissions) && (
                            <button
                                onClick={() => sendApprovalToBackend()}
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white">
                                Marcar como Solicitud Pagada
                            </button>
                        )}
                        { (['A la espera de revisión por parte del solicitante'].includes(expenseReportRequest.status) && (permissions.hasResendPermissions)) && (
                            <button
                                onClick={() => sendApprovalToBackend()}
                                className="w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-400 hover:bg-blue-600 text-white">
                                Reenviar solicitud
                            </button>
                        )}
                    </div>
                    


                    <div className="mt-8 text-center">
                        <h3 className="text-lg font-semibold text-left">Próximos Aprobadores (antes de Contabilidad):</h3>
                        <List>
                        {nextApprovers.map((approver: any, index: number) => (
                            <ListItem key={index}>
                            <ListItemText
                                primary={`${index + 1} - ${approver.user_name}`}
                                secondary={`RUT: ${approver.rut}`}
                            />
                            </ListItem>
                        ))}
                        </List>
                    </div>
                </div>
            </div>
      </div>
    )
}

const ActionDictionary : any = {
    "Aprobar": "Aprobó la solicitud",
    "Rechazar": "Rechazó la solicitud",
    "Devolver": "Devolvió la solicitud",
    "Recibir": "Marcó la solicitud como recibida",
    "Reenviar": "Reenvió la solicitud",
    "Validada por Contabilidad": "Envió la solicitud a Tesorería",
    "Pagada por Tesorería": "Tesorería ya pagó la solicitud"
}
